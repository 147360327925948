<template>
  <div>
    <header-admin-pages
            title="Медиа"
            :counter="getMedias.length"
            :button="headerButton"/>

    <div class="row">
      <div class="search-field">
        <label for="key">
          Поиск:
        </label>
        <input type="text" id="key" class="input blue" v-model="search.key">
      </div>

      <div class="search-field">
        <label for="key">
          Дата от:
        </label>
        <input type="date" id="date-start" class="input blue" v-model="search.date_start">
      </div>

      <div class="search-field">
        <label for="key">
          Дата до:
        </label>
        <input type="date" id="date-end" class="input blue" v-model="search.date_end">
      </div>

      <div class="search-field">
        <label for="rubric">
          Рубрика:
        </label>
        <select name="rubric" id="rubric" class="input blue" v-model="search.rubric">
          <option :value="null">
            -
          </option>
          <option :value="rubric.id" v-for="(rubric, index) in getRubrics" :key="`rubric${index}`">
            {{ rubric.title }}
          </option>
        </select>
      </div>

      <button class="btn-blue" @click="setSearch">
        Поиск
      </button>
    </div>

    <table>
      <tr>
        <td>Заголовок</td>
        <td>Описание</td>
        <td>Дата</td>
        <td>Тип</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="media in getMedias" :key="`media${media.id}`">
        <td>{{ media.title }}</td>
        <td>{{ media.description }}</td>
        <td>{{ getFormattedDate(media.date) }}</td>
        <td>{{ media.media_type === 1 ? 'Фото' : 'Видео' }}</td>
        <td class="drop-icon">
          <drop-list @clickItem="clickItem"
                     :id-item="media.id"
                     :edit-link="`/admin/media/${media.id}/edit`"/>
        </td>
      </tr>
    </table>

    <pagination class="pagination__block"
                :per-page="getAdminMediaPagination.limit"
                :current="getAdminMediaPagination.offset / getAdminMediaPagination.limit + 1"
                :total="getAdminMediaPagination.total"
                @page-changed="getPage"/>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
import formatDate from '@/mixins/formatDate'
import Pagination from '@/components/Pagination'

export default {
  name: 'Medias',

  data () {
    return {
      headerButton: {
        title: 'Добавить медиа',
        link: '/admin/media/create'
      },
      search: {
        key: null,
        date_start: null,
        date_end: null,
        rubric: null
      }
    }
  },

  created () {
    this.$store.dispatch('getRubrics')
    this.$store.dispatch('getMedias')
    this.$store.dispatch('getMediaTypes')
  },

  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить медиа?')
        if (consent) {
          this.$store.dispatch('deleteMedia', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    },

    setSearch () {
      this.$store.commit('setAdminMediaSearch', this.search)
      this.$store.dispatch('getMedias')
    },

    getPage (page) {
      this.$store.commit('setAdminMediaPaginationOffset', (page - 1) * this.getAdminMediaPagination.limit)
      this.$store.dispatch('getMedias')
    }
  },

  computed: {
    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getMedias () {
      return this.$store.getters.getMedias
    },

    getAdminMediaPagination () {
      return this.$store.getters.getAdminMediaPagination
    }
  },

  components: {
    Pagination,
    DropList,
    HeaderAdminPages
  },

  mixins: [formatDate]
}
</script>

<style scoped lang="scss">
  .pagination__block {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    .search-field {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: .5rem 0;

      label {
        text-align: left;
        font-size: .812rem;
        line-height: 154%;
        color: #5E686C;
      }
    }

    button {
      height: 3rem;
      min-width: 5rem;
      width: 10rem;
    }
  }

  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
